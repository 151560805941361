<nav class="nav flex-column">
  <p class="navbar-verticle-label">Settings & Configuration</p>
  <a routerLink="/configuration/studio-information" routerLinkActive="active" class="nav-link" aria-current="page">
    <mat-icon>info</mat-icon> Studio Information
  </a>
  <a routerLink="/configuration/tax-configuration" routerLinkActive="active" class="nav-link">
    <mat-icon>account_balance</mat-icon> Tax Configuration
  </a>
  <a routerLink="/configuration/calendar-configuration" routerLinkActive="active" class="nav-link">
    <mat-icon>calendar_today</mat-icon> Calendar Configuration
  </a>
  <!-- <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>history</mat-icon> Audit Trail
  </a> -->
</nav>

<nav class="nav flex-column mt-4">
  <p class="navbar-verticle-label">Staff</p>
  <a routerLink="/configuration/staff-management" routerLinkActive="active" class="nav-link">
    <mat-icon>people</mat-icon> Staff Management
  </a>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>lock</mat-icon> Permissions
  </a>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>payments</mat-icon> Payroll
  </a>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>chat</mat-icon> Communications
  </a>
</nav>

<nav class="nav flex-column mt-4">
  <p class="navbar-verticle-label">Marketing & Communication</p>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>email</mat-icon> Email Campaign
  </a>
  <a routerLink="/configuration/gift-certificates" routerLinkActive="active" class="nav-link">
    <mat-icon>card_giftcard</mat-icon> Gift Certificates
  </a>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>feedback</mat-icon> Feedback & Surveys
  </a>
  <a routerLink="/configuration/email-template-settings" routerLinkActive="active" class="nav-link">
    <mat-icon>settings</mat-icon> Email Configuration
  </a>
  <a routerLink="/configuration/sms-template-settings" routerLinkActive="active" class="nav-link">
    <mat-icon>sms</mat-icon> SMS Configuration
  </a>
</nav>

<nav class="nav flex-column mt-4">
  <p class="navbar-verticle-label">Import & Data Migration</p>
  <a (click)="toggleSimpleImport()" class="nav-link" style="cursor: pointer;">
    <mat-icon>file_download</mat-icon> Simple Import
  </a>
  <div *ngIf="showSimpleImport">
    <a routerLink="/configuration/simple-import-instructors" routerLinkActive="active" class="nav-link nested-link">
      <mat-icon>person</mat-icon> Instructors
    </a>
    <a routerLink="/configuration/simple-import-packages" routerLinkActive="active" class="nav-link nested-link">
      <mat-icon>local_offer</mat-icon> Packages
    </a>
    <a routerLink="/configuration/simple-import-student-accounts" routerLinkActive="active" class="nav-link nested-link">
      <mat-icon>school</mat-icon> Student Accounts
    </a>
    <a routerLink="/configuration/simple-import-history" routerLinkActive="active" class="nav-link nested-link">
      <mat-icon>history</mat-icon> History
    </a>
  </div>
  <a routerLink="/configuration/floatingrain-import" class="nav-link disabled">
    <mat-icon>cloud_download</mat-icon> Floating Rain Import
  </a>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>backup</mat-icon> Export / Backup
  </a>
</nav>

<nav class="nav flex-column mt-4">
  <p class="navbar-verticle-label">Integrations</p>
  <a routerLink="/configuration" class="nav-link disabled">
    <mat-icon>description</mat-icon> Quickbooks
  </a>
  <a routerLink="/configuration/stripe-integration" routerLinkActive="active" class="nav-link">
    <i class="fa-brands fa-stripe-s"></i> Stripe
  </a>
  <a routerLink="/configuration/square-integration" routerLinkActive="active" class="nav-link">
    <i class="fa-regular fa-square"></i> Square
  </a>
  <a routerLink="/configuration/stripe-twilio" routerLinkActive="active" class="nav-link">
    <mat-icon>phone</mat-icon> Twilio
  </a>
</nav>
