import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';

interface TaxonomyTerm {
  id: string;
  name: string;
  weight: number;
  color: string;
  pastEventColor?: string;
  opacity: number | null;
  hide: boolean;
  published: boolean;
}

interface APITaxonomyOverride {
  id: [{ value: string }];
  uuid: [{ value: string }];
  field_color: [{ value: string }];
  field_past_event_color: [{ value: string }];
  field_hide: [{ value: string }];
  field_published: [{ value: string }];
  field_term_id: [{ value: string }];
  field_vocabulary: [{ value: string }];
  field_weight: any[];
}

interface TaxonomyOverride {
  id: string;
  field_vocabulary: string;
  field_term_id: string;
  field_color: string;
  field_past_event_color: string;
  field_hide: boolean;
  field_published: boolean;
  field_weight?: number;
}

@Component({
  selector: 'app-calendar-color-form',
  templateUrl: './calendar-color-form.component.html',
  styleUrls: ['./calendar-color-form.component.css']
})
export class CalendarColorFormComponent implements OnInit {
  calendarColorForm: FormGroup;
  taxonomyTypes = ['lesson_type', 'schedule_type', 'service_status', 'service_type'];
  showProgressSpinner = false;
  successMessage = '';
  errorMessage = '';
  overrides: any = {};
  hasChanges = false;

  constructor(
    private fb: FormBuilder,
    private taxonomyService: TaxonomyService,
    private drupalRESTService: DrupalRESTService
  ) {
    this.initEmptyForm();
  }

  ngOnInit(): void {
    this.loadTaxonomyTerms();
  }

  initEmptyForm() {
    this.calendarColorForm = this.fb.group({});
    this.taxonomyTypes.forEach(type => {
      this.calendarColorForm.addControl(type, this.fb.array([]));
    });
  }

  async loadTaxonomyTerms() {
    this.showProgressSpinner = true;
    try {
      await this.taxonomyService.initTaxonomyTerms();
      await this.loadOverrides();
      this.initForm();
    } catch (error) {
      console.error('Error loading taxonomy terms:', error);
      this.errorMessage = 'Failed to load taxonomy terms.';
    } finally {
      this.showProgressSpinner = false;
    }
  }

  async loadOverrides() {
    const configFieldName = 'field_taxonomy_overrides_ref';
    try {
      const response: any = await this.drupalRESTService
        .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
        .toPromise();

      let overridesArray: any[];

      if (Array.isArray(response)) {
        overridesArray = response;
      } else if (typeof response === 'object' && response !== null) {
        overridesArray = [response];
      } else {
        throw new Error('Invalid response format');
      }

      const validOverrides: APITaxonomyOverride[] = overridesArray.filter((item): item is APITaxonomyOverride => {
        return (
          item &&
          Array.isArray(item.id) && item.id[0]?.value &&
          Array.isArray(item.field_vocabulary) && item.field_vocabulary[0]?.value &&
          Array.isArray(item.field_term_id) && item.field_term_id[0]?.value &&
          Array.isArray(item.field_color) && item.field_color[0]?.value &&
          Array.isArray(item.field_hide) && item.field_hide[0]?.value &&
          Array.isArray(item.field_published) && item.field_published[0]?.value
        );
      });

      this.overrides = validOverrides.reduce((acc, apiOverride) => {
        const override: TaxonomyOverride = {
          id: apiOverride.id[0].value,
          field_vocabulary: apiOverride.field_vocabulary[0].value,
          field_term_id: apiOverride.field_term_id[0].value,
          field_color: apiOverride.field_color[0].value,
          field_past_event_color: apiOverride.field_past_event_color && apiOverride.field_past_event_color[0]?.value,
          field_hide: apiOverride.field_hide[0].value === '1',
          field_published: apiOverride.field_published[0].value === '1',
          field_weight: apiOverride.field_weight && apiOverride.field_weight[0]?.value
            ? parseInt(apiOverride.field_weight[0].value)
            : undefined
        };

        if (!acc[override.field_vocabulary]) {
          acc[override.field_vocabulary] = [];
        }
        acc[override.field_vocabulary].push(override);
        return acc;
      }, {} as { [key: string]: TaxonomyOverride[] });

      console.log('Loaded overrides:', this.overrides);
    } catch (error) {
      console.error('Error loading overrides:', error);
      this.errorMessage = 'Failed to load color overrides: ' + (error.message || 'Unknown error');
    }
  }

  initForm() {
    this.calendarColorForm = this.fb.group({});

    this.taxonomyTypes.forEach(type => {
      const formArray = this.fb.array([]);
      this.calendarColorForm.addControl(type, formArray);

      (this.taxonomyService[type] as TaxonomyTerm[])?.forEach((term: TaxonomyTerm) => {
        const override = this.overrides[type]?.find(o => o.field_term_id === term.id);

        const formGroup = this.fb.group({
          id: [term.id],
          name: [term.name],
          color: [override?.field_color || term.color, [Validators.required, Validators.pattern(/^#[0-9A-Fa-f]{6}$/)]],
          pastEventColor: [override?.field_past_event_color || term.pastEventColor || term.color, [Validators.required, Validators.pattern(/^#[0-9A-Fa-f]{6}$/)]],
          hide: [override?.field_hide ?? term.hide],
          published: [override?.field_published ?? term.published],
          isOverridden: [!!override]
        });

        formArray.push(formGroup);

        console.log(`Initialized form group for ${type}:`, {
          id: term.id,
          name: term.name,
          color: formGroup.get('color').value,
          pastEventColor: formGroup.get('pastEventColor').value,
          hide: formGroup.get('hide').value,
          published: formGroup.get('published').value,
          isOverridden: formGroup.get('isOverridden').value
        });
      });
    });

    this.calendarColorForm.valueChanges.subscribe(() => {
      this.checkForChanges();
    });

    console.log('Form initialized:', this.calendarColorForm.value);
  }

  getFormArray(type: string): FormArray {
    return this.calendarColorForm.get(type) as FormArray;
  }

  onSubmit() {
    if (this.hasChanges) {
      const overrides: APITaxonomyOverride[] = [];
      this.taxonomyTypes.forEach(type => {
        const formArray = this.calendarColorForm.get(type) as FormArray;
        formArray.controls.forEach((control: FormGroup) => {
          if (control.get('isOverridden').value) {
            overrides.push({
              id: [{ value: control.get('id').value }],
              uuid: [{ value: '' }], // You might need to handle this differently
              field_vocabulary: [{ value: type }],
              field_term_id: [{ value: control.get('id').value }],
              field_color: [{ value: control.get('color').value }],
              field_past_event_color: [{ value: control.get('pastEventColor').value }],
              field_hide: [{ value: control.get('hide').value ? '1' : '0' }],
              field_published: [{ value: control.get('published').value ? '1' : '0' }],
              field_weight: []
            });
          }
        });
      });

      const postData = {
        config_field_name: 'field_taxonomy_overrides_ref',
        config_data: overrides
      };

      this.drupalRESTService.httpPOST('/api_rest/v1/saveStudioConfig', postData).subscribe(
        (response) => {
          console.log('Taxonomy overrides updated successfully:', response);
          this.successMessage = 'Calendar colors updated successfully.';
          this.showProgressSpinner = false;
          this.loadTaxonomyTerms(); // Refresh taxonomy terms and overrides after update
        },
        (error) => {
          console.error('Error updating taxonomy overrides:', error);
          this.errorMessage = 'Failed to update calendar colors. Error: ' + (error.message || 'Unknown error');
          this.showProgressSpinner = false;
        }
      );
    } else {
      console.log('Form has no changes');
    }
  }

  checkForChanges() {
    let previousHasChanges = this.hasChanges;
    this.hasChanges = false;
    this.taxonomyTypes.forEach(type => {
      const formArray = this.getFormArray(type);
      formArray.controls.forEach((control: FormGroup) => {
        if (control.get('isOverridden').value) {
          this.hasChanges = true;
        }
      });
    });
    if (this.hasChanges !== previousHasChanges) {
      console.log('hasChanges updated:', this.hasChanges);
    }
  }

  onValueChange(type: string, index: number) {
    const formGroup = this.getFormArray(type).at(index) as FormGroup;
    const term = this.taxonomyService[type][index];
    const color = formGroup.get('color').value;
    const pastEventColor = formGroup.get('pastEventColor').value || color;

    formGroup.patchValue({ pastEventColor: pastEventColor }, { emitEvent: false });

    const isOverridden =
      color !== term.color ||
      pastEventColor !== (term.pastEventColor || term.color) ||
      formGroup.get('hide').value !== term.hide ||
      formGroup.get('published').value !== term.published;

    formGroup.patchValue({ isOverridden: isOverridden }, { emitEvent: false });
    console.log(`Value changed for ${type} at index ${index}. Is overridden: ${isOverridden}`);
    this.checkForChanges();
  }

  resetToDefault(type: string, index: number) {
    const term = this.taxonomyService[type][index];
    const formGroup = this.getFormArray(type).at(index) as FormGroup;
    formGroup.patchValue({
      color: term.color,
      pastEventColor: term.pastEventColor || term.color,
      hide: term.hide,
      published: term.published,
      isOverridden: false
    });
    this.checkForChanges();
  }

  getTaxonomyTypeDisplayName(type: string): string {
    return type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  isOverridden(type: string, index: number): boolean {
    return this.getFormArray(type).at(index).get('isOverridden').value;
  }
}
