<mat-card class="configuration-card">
  <mat-card-header>
    <mat-card-title>Calendar Color Configuration</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div>
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>
      <form [formGroup]="calendarColorForm" (ngSubmit)="onSubmit()">
        <app-success-message [message]="successMessage"></app-success-message>
        <app-error-message [message]="errorMessage"></app-error-message>

        <mat-accordion>
          <mat-expansion-panel *ngFor="let type of taxonomyTypes">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ getTaxonomyTypeDisplayName(type) }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div [formArrayName]="type">
              <div *ngFor="let term of getFormArray(type)?.controls; let i = index" [formGroupName]="i" class="row mb-3">
                <ng-container *ngIf="term">
                  <div class="col-4">
                    <mat-form-field class="full-width-field" appearance="outline">
                      <mat-label>{{ getTaxonomyTypeDisplayName(type) }}</mat-label>
                      <input matInput [value]="term.get('name')?.value" readonly>
                    </mat-form-field>
                  </div>
                  <div class="col-2">
                    <mat-form-field class="full-width-field" appearance="outline">
                      <mat-label>Future Event Color</mat-label>
                      <input matInput type="color" formControlName="color" (change)="onValueChange(type, i)">
                      <mat-error *ngIf="term.get('color')?.invalid">Please enter a valid hex color code.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-2">
                    <mat-form-field class="full-width-field" appearance="outline">
                      <mat-label>Past Event Color</mat-label>
                      <input matInput type="color" formControlName="pastEventColor" (change)="onValueChange(type, i)">
                      <mat-error *ngIf="term.get('pastEventColor')?.invalid">Please enter a valid hex color code.</mat-error>
                    </mat-form-field>
                  </div>
                  <div class="col-1">
                    <mat-checkbox formControlName="hide" (change)="onValueChange(type, i)">Hide</mat-checkbox>
                  </div>
                  <div class="col-2">
                    <mat-checkbox formControlName="published" (change)="onValueChange(type, i)">Published</mat-checkbox>
                  </div>
                  <div class="col-1">
                    <button mat-icon-button type="button" (click)="resetToDefault(type, i)" matTooltip="Reset to Default" [disabled]="!isOverridden(type, i)">
                      <mat-icon>refresh</mat-icon>
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </form>
    </div>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="accent" type="submit" [disabled]="!hasChanges" (click)="onSubmit()">
      Update Colors
    </button>
  </mat-card-actions>
</mat-card>
